import {isAfter, parseISO} from "date-fns";

export function isDigitalSubscribed(user) {
  return !!(user?.subscriptions
      ?.some(s => ['full_access', 'digital'].includes(s.format.toLowerCase())
          && isAfter(parseISO(s.currentPeriodEnd), new Date())
          && s.status === "active"));
}

export function isSubscribed(user) {
  return !!(user?.subscriptions
      ?.some(s => isAfter(parseISO(s.currentPeriodEnd), new Date())
          && s.status === "active"));
}

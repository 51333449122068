import React from 'react';
import styles from './StoryList.module.scss';
import classNames from 'classnames/bind';
import getImageUrls from "@/lib/utils/getImageUrls";
import Link from "next/link";
import {getDeepestCategory, getStorySitePath, storyToPath} from "@/lib/utils/storyUtils";
import CtaArrow from "@/components/svg/CtaArrow";
import CategoryLink from "@/components/link/categoryLink/CategoryLink";
import { useContext } from '@/lib/utils/context';
import LazyImage from '@/components/lazyImage/LazyImage';

const cx = classNames.bind(styles);

function MyStoryCard({story}) {
  const {site} = useContext();
  const {sponsored} = story;
  const category = getDeepestCategory(story) ?? story.allCategories.find(c => c.site === site.slug);
  const storyLink = story.categories.length ? storyToPath(story) : getStorySitePath({story, site: site.slug});

  const thumbnail = getImageUrls(story.thumbnail, {width: 600, height: 600});

  return (
      <div className={cx(styles.storyCard)}>
        <Link href={storyLink}>
          <a>
            <LazyImage className={styles.thumbnail} image={thumbnail.image} alt={thumbnail.alt}/>
          </a>
        </Link>
        <div className={styles.text}>
          <div className={styles.categoryLine}>
            <CategoryLink category={category} />
            {sponsored && <div className={styles.sponsored}>Sponsored</div>}
          </div>
          <Link href={storyLink}>
            <a>
              <div className={styles.headline}>{story.headline}</div>
              <div className={styles.excerpt}>{story.excerpt}</div>
              <div className={styles.readMore}>
                <div className={styles.readMoreText}>Read</div>
                <CtaArrow className={styles.arrowIcon}/>
              </div>
            </a>
          </Link>
        </div>
      </div>
  )
}

export default function StoryList({className, stories = []}) {
  return (
      <div className={cx(styles.storyList, className)}>
        {stories.map((story, i) => (
            <MyStoryCard key={i} story={story}/>
        ))}
      </div>
  );
}

import React from 'react';
import styles from './SignupWidget.module.scss';
import classNames from 'classnames/bind';
import Link from 'next/link';
import Button from '@/components/button/Button';
import {useContext} from "@/lib/utils/context";
import {useRouter} from "next/router";
import {toWords} from "number-to-words";

const cx = classNames.bind(styles);

export default function SignupWidget({className}) {
  const router = useRouter();
  const {config, user, site} = useContext();
  const articleLimit = config.articleLimits.member;
  const articleCount = user?.freeStoryIds?.length ?? 0;
  const limitReached = articleCount >= articleLimit;

  return (
      <div className={cx(styles.signupWidget, className)}>
        {limitReached ? (
            <>
              <strong>
                <em>{articleLimit} / {articleLimit} articles read</em>
              </strong>
              <h2>You have reached your free article limit</h2>
              <p>
                We’re glad you’re enjoying {site.name}&nbsp;Magazine.
                Subscribe now for unlimited access to even more great articles.
              </p>
            </>
        ) : (
            <>
              {user
                  ? <>
                    <h2>Enjoy {articleLimit} free articles</h2>
                    <strong>
                      <em>You have read {articleCount} / {articleLimit} articles</em>
                    </strong>
                    <p>
                      We’re glad you’re enjoying {site.name}&nbsp;Magazine.
                      Subscribe now for unlimited access to even more great articles.
                    </p>
                  </>
                  : <>
                    <h2>Sign up to access <em>{articleLimit} free articles</em></h2>
                    <p>
                      Treat yourself to a taste of {site.name}&nbsp;Magazine with free access
                      to {toWords(articleLimit)} great articles.
                    </p>
                  </>}
            </>
        )}
        <Link href={{pathname: `/${user ? "subscribe" : "signup"}`, query: {onSuccess: router.asPath}}}>
          <a>
            <Button className={styles.button} black>
              {`${user ? "Subscribe" : "Sign up"}`}
            </Button>
          </a>
        </Link>
      </div>
  );
}

import React from 'react';
import styles from './TrendingStories.module.scss';
import classNames from 'classnames/bind';
import getImageUrls from "@/lib/utils/getImageUrls";
import Link from "next/link";
import {getDeepestCategory, storyToPath} from "@/lib/utils/storyUtils";
import CtaArrow from "@/components/svg/CtaArrow";
import CategoryLink from "@/components/link/categoryLink/CategoryLink";
import LazyImage from '@/components/lazyImage/LazyImage';

const cx = classNames.bind(styles);

function MyStoryCard({story}) {

  const {sponsored} = story;
  const category = getDeepestCategory(story);

  const thumbnail = getImageUrls(story.thumbnail, {width: 600, height: 600});

  return (
      <div className={cx(styles.storyCard)}>
        <Link href={storyToPath(story)}>
          <a>
            <img className={cx(styles.thumbnail)} src={thumbnail.image.url} alt={thumbnail.alt}/>
          </a>
        </Link>
        <div className={styles.text}>
          <div className={styles.categoryLine}>
            <CategoryLink category={category}/>
            {sponsored && <div className={styles.sponsored}>Sponsored</div>}
          </div>
          <Link href={storyToPath(story)}>
            <a>
              <h2>{story.headline}</h2>
              <div className={styles.readMore}>
                <div className={styles.readMoreText}>Read</div>
                <CtaArrow className={styles.arrowIcon}/>
              </div>
            </a>
          </Link>
        </div>
      </div>
  )
}

export default function TrendingStories({className, stories}) {
  return (
      <div className={cx(styles.trendingStoriesContainer, className)}>
        {stories.map((story, i) => (
            <MyStoryCard key={i} story={story}/>
        ))}
      </div>
  );
}

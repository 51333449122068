import React, {useState} from 'react';
import styles from './NewsletterSignupWidget.module.scss';
import classNames from 'classnames/bind';
import EmailInput from '@/components/input/email/EmailInput';
import {useForm} from 'react-hook-form';
import Tick from '@/components/svg/Tick';
import {createContact} from '@/lib/api/api';
import {useContext} from '@/lib/utils/context';
import Loader from '@/components/loader/Loader';
import InputArrow from "@/components/svg/InputArrow";

const cx = classNames.bind(styles);

export default function NewsletterSignupWidget({ className }) {
  const { setError } = useContext();
  const [submitting, setSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { register, handleSubmit, watch } = useForm();
  const formValues = watch();

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const response = await createContact(formValues);
      if (response) {
        setFormSubmitted(true);
      }
    } catch (error) {
      setError(true);
    }
    finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={cx(styles.newsletterSignupWidget, className)}>
      <h2>Stay in the loop</h2>
      <p>Sign up to our newsletter to stay updated with new content</p>
      {submitting && (
        <div className={styles.loaderContainer}>
          <Loader className={styles.loader} />
        </div>
      )}
      {!submitting && formSubmitted && (
        <div className={styles.successBox}>
          <div className={styles.successMessage}>
            Cool, you're now signed up!
          </div>
          <Tick className={styles.successIcon}/>
        </div>
      )}
      {!submitting && !formSubmitted && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputContainer}>
            <EmailInput
              className={styles.input}
              required
              placeholder="Enter your email"
              register={register}
              name={'email'}
            />
            <button type="submit" className={styles.submitButton}>
              <InputArrow className={styles.arrowIcon}/>
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

import React from "react";

export default function Tick({className}) {
  return (
      <svg className={className} viewBox="0 0 16 12">
        <line x1="4.49146" y1="11.1582" x2="14.4915" y2="1.15821" stroke="currentColor" strokeWidth="2.38095"/>
        <line x1="5.40077" y1="10.4155" x2="1.11506" y2="5.65362" stroke="currentColor" strokeWidth="2.38095"/>
      </svg>
  )
}
